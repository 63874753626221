import { Box, Grid, Typography } from "@mui/material"

interface Service{
    name: string,
    description: string,
    price: string,
    subText?: string | undefined
}

const haircuts:Service[] = [
    {
        name: 'Mens Haircut',
        price: '$45',
        description: 'Haircut with straight razor clean up'
    },
    {
        name: 'Mens Haircut & Shampoo',
        price: '$50',
        description: 'Mens cut and shampoo'
    },
    {
        name: 'Boys Haircut',
        price: '$40',
        description: 'Only if a booster is used'
    },
    {
        name: 'Girls Haircut',
        price: '$50',
        description: 'Only if a booster is used'
    },
    {
        name: 'Womens Haircut',
        price: '$70 and up',
        description: 'Shampoo, haircut, and style. Price goes up depending on length and volume'
    }
]

const shaves:Service[] = [
    {
        name: 'Hot Towel Face Shave',
        price: '$45',
        description: 'We use essential oils and a warm towel to end with a straight razor shave'
    },
    {
        name: 'Beard Trim and Line Up',
        price: '$35',
        description: ''
    },
]


const grooming:Service[] = [
    {
        name: 'Eyebrow Waxing',
        price: '$20',
        description: ''
    }
]

const colorServices:Service[] = [
    {
        name: 'Balayage',
        price: '$280',
        description: 'Starting at $280, price varies depending on length and density of hair',
        subText: '($50 deposit required for reservation)'
    },
    {
        name: 'Highlights',
        price: '$280',
        description: '$180 partial highlights (half of head), $280 full head, price varíes depending on length and fullness',
        subText: '($50 deposit required for reservation)'
    },
    {
        name: 'Balayage and highlight retouch',
        price: '$180+',
        description: '',
        subText: '($50 deposit required for reservation)'
    },
    {
        name: 'Bleach retouch with all over color',
        price: '$280+',
        description: '$280+ depending on length and density of hair'
    },
    {
        name: 'Toner',
        price: '$70',
        description: 'Toner refresh your blonde, removing brass and neutralizing unwanted tones',
        subText: '($30 deposit required for reservation)'
    },
    {
        name: 'Full Color',
        price: '$140+',
        description: 'single color',
        subText: '($30 deposit required for reservation)'
    },
    {
        name: 'Color Re-Touch',
        price: '$120',
        description: '1/2 inch off head, if grown past 1/2 inch price goes up',
        subText: '($30 deposit required for reservation)'
    },
    {
        name: 'Olaplex Treatment',
        price: '$120',
        description: 'Hair treatment that helps damaged and dry hair come back to a healthy softness and shine',
        subText: '($30 deposit required for reservation)'
    },
    {
        name: 'Mens Bleaching',
        price: '$180+',
        description: 'Price varies depending on length',
        subText: '($50 deposit required for reservation)'
    },
]


const otherServices:Service[] = [
    {
        name: '2 French Braids',
        price: '$35 - $70',
        description: ''
    },
    {
        name: 'Wax',
        price: '$15',
        description: ''
    },
    {
        name: 'Thermal Style',
        price: '$50+',
        description: 'Shampoo, dry and style'
    }
]

interface ServiceSection{
    name: string,
    description: string,
    services: Service[],

}

const serviceSections: ServiceSection[] = [
    {
        name: 'Haircuts',
        description:'Old school style with fresh new flair',
        services: haircuts
    },
    {
        name: 'Shave',
        description:'So fresh and so clean',
        services: shaves
    },
    {
        name: 'Grooming',
        description:'When you need to look your best',
        services: grooming
    },
    {
        name: 'Color',
        description:'$50 deposit to reserve your appointment, we will confirm your appointment once deposit has been received.',
        services: colorServices
    },
    {
        name: 'Other Services',
        description:'All the odds and ends',
        services: otherServices
    },
]

const ServiceItem = ({ name, description, price, subText }:{ name: string, description: string, price: string, subText?: string }) =>{

    return(
        <Box sx={{margin:'15px'}}>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{color:'#f7f7f7', textAlign:'left', fontWeight: 500, fontSize:'22px'}}>
                        {name &&
                            name
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{color:'#f7f7f7', textAlign:{xs: 'left', sm: 'right', fontWeight: 500, fontSize:'22px'}}}>
                        {price &&
                            price
                        }
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{color:'#a4a4a4', textAlign:'left'}}>
                        {description &&
                            description
                        }
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{color:'#a4a4a4', textAlign:'left', fontWeight:'600'}}>
                        {subText &&
                            subText
                        }
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    )
}

export const Services = () => {

    return(
        <Box sx={{width:'auto', justifyContent:'center', alignItems:'center', textAlign:'center', paddingTop:{xs:'20%', sm:'20%'}, marginLeft:'20px', marginRight:'20px'}}>
            <Grid 
                container 
                alignItems='center' 
                justifyContent='center'
                
                sx={{maxWidth:'900px'}}
            >
              
                <Grid 
                    item 
                    xs={12}
                    sx={{width:'100%', marginBottom:'40px'}}
                >
                    <Typography fontWeight={800} color='#636363' fontSize={{xs:'33px', sm:'36px', md:'40px', lg:'44px'}}>
                        SERVICES
                    </Typography>
                </Grid>
                
             
                    {serviceSections.map((section, index)=>
                        <Grid key={`${section.name}-container`} container sx={{borderBottom: index < serviceSections.length - 1? '1px solid #646464': 'none', marginTop:'30px'}}>
                            <Grid 
                                item 
                                xs={12} 
                                textAlign='left'
                                
                            >
                                <Typography
                                    sx={{
                                        width: '100%',
                                        color: '#636363',
                                        fontSize: {xs: '28px', sm: 'clamp(1.75rem, 1.6853rem + 0.4706vw, 2.25rem)'}
                                    }}
                                >
                                    {section.name &&
                                        section.name
                                    }
                                </Typography>

                                <Typography
                                    sx={{
                                        width: '100%',
                                        color: '#a9a9a9',
                                        fontSize: {xs: '16px'}
                                    }}
                                >
                                {section.description &&
                                        section.description
                                }
                                </Typography>
                            </Grid>


                            <Grid 
                                xs={12}
                                item
                                 
                            >
                                {section.services.map((service)=>
                                    <div  key={service.name} >
                                        <ServiceItem name={service.name} description={service.description} price={service.price} subText={service.subText}/>
                                    </div>
                                )}
                            </Grid>

                        </Grid>
                    )}
                    

                
              
            </Grid>
        </Box>
    )
}