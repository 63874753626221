import * as React from 'react';
import { useState } from 'react';
import { keyframes } from '@mui/material';

import { 
    Dialog, 
    Grid,
    Button,
    Typography,
    Fab,
    Zoom
} from '@mui/material'

import { TransitionProps } from '@mui/material/transitions';

import { Demodal, useModal } from "demodal";

import { muiDialog } from 'demodal/material-ui'
import { barbers, Barber } from './barbers';
import { Close } from '@mui/icons-material';
import { itemFocusedAnimation } from '../common/focus';

// const ParseDeadblockData = ({pct:pct}:  {pct:any}) => {
//     if(pct === 'Fail'){
//       return  <div className='clear-icon-bg'  style={{display:'inline-flex'}}/>
//     }
//     else if (pct.length >= 1){
//       return pct.replace('%','')
//     }
//     else{
//       return  <Grid container className='clear-icon-bg'  style={{display:'inline-flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}/>
//     }
//   }


enum BookingScreens {
    SelectBarber = 0,
    SelectServiceAndCheckout = 1
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Zoom  ref={ref} {...props} />;
});

const  barberFocus = keyframes`

0% { 
    opacity: 1;
    background-position: 70% 70%;
    outline: 1px solid rgba(255,255,255,.3);
}

50% { 
    opacity: 1;
    background-position: 10% 30%;
    outline: 1px solid rgba(255,255,255,1);
}



100% { 
    opacity: 1;
    background-position: 0% 30%;
    outline: 1px solid rgba(255,255,255,.3);
}

`


export const SquareBookingModal = Demodal.create(
    (
        
    ) => {

    const squareBookingModal = useModal()
    const [currentScreen, setCurrentScreen] = useState(0)
    const [selectedBarber, setSelectedBarber] = useState(barbers[0])

    const handleBarberSelect = ({barber}: {barber: Barber}) => {
        setSelectedBarber(barber)
        setCurrentScreen(BookingScreens.SelectServiceAndCheckout)
    }

    const BarberSelect = () => {
        return (
            <Grid container sx={{width: '100%', maxWidth:'320px', justifyContent:'center', alignItems:'center', padding:"auto", margin:'auto', maxHeight:"max-content"}}>
                
                <Grid item xs={12}>
                    <Typography fontWeight={800} color='#636363' textAlign='center' fontSize={{xs:'26px', sm:'30px', md:'33px', lg:'36px'}} mt={2}>
                        Select Barber
                    </Typography>
                </Grid>
                <Grid container justifyItems='center' alignItems='center' justifyContent='center' sx={{padding:'20px'}}>
                    {barbers.map((barber) => 
                        <Grid 
                            container 
                            spacing={2}
                            sx={{
                                width: '100%', 
                                justifyContent:'center', alignItems:'center', 
                                padding:"auto", margin:'auto',
                                paddingBottom:'15px',
                                cursor: 'pointer',
                                borderRadius: '6px',
                                opacity: 0.7,
                                outline: '1px solid rgba(255,255,255,0)',
                                transition: 'all .2s ease-in-out',
                                '&:hover':{
                                    opacity: 1,
                                    bgcolor: 'rgb(40,40,40)'
                                },
                                '&:focus':{
                                    opacity: 1,
                                    
                                    animation: `${itemFocusedAnimation} 2s ease-in-out infinite`
                                }
                            }}  
                            key={barber.name} 
                            onClick={()=>handleBarberSelect({barber:barber})}
                            onKeyDownCapture = {(e) => {
                                if (e.key === 'Enter') {
                                    handleBarberSelect({barber:barber})
                                }
                                else if (e.key === ' ') {
                                    handleBarberSelect({barber:barber})
                                }       
                            }}  
                            tabIndex={0}
                            aria-label={`Schedule an appointment with ${barber.name}`}
                        >
                            <Grid item xs={6} >
                                <img 
                                    src={barber.image} alt={barber.name}
                                    style={{ width: '80%', height: '80%', display: 'flex', borderRadius: '50%' }}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <Typography fontWeight={400} fontSize='18pt'>
                                    {barber.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                    }
                </Grid>
            </Grid>
        )
    }

    // const ServicesAndPurchase = () => {
    //     <Grid container  sx={{margin:'0px', padding:'0px', height:'60vh', width:'auto', display:'flex', filter:'invert(91.5%)'}}>
    //         <iframe style={{ margin:'0px', padding:'0px',minWidth: '320px', border:'none'}} src='https://squareup.com/appointments/book/a1deb5e0-435f-4d8b-8358-0c5b7e38f793/2WYPSWE8ZTKKN/start'>
    //         </iframe>
    //     </Grid>
    // }   
    
    return (
        <Dialog 
            {...muiDialog(squareBookingModal)}
            PaperProps={{
                style: { 
                    borderStyle: 'solid', borderColor:'rgba(100,100,100,1)', borderWidth:'1px', maxHeight:'max-content',
                }   
            }}
            sx={{
                bgcolor:'rgba(0,0,0,.8)',
                transition:'all 1s ease-in-out'
            }}
            onClose={()=>{}}
            TransitionComponent={Transition}
        >
            <Grid 
                item xs={12}  
                position='absolute' 
                sx={{
                    // top:'calc(20vh - 25px)', 
                    // left:'calc(100% - 60px)',
                    zIndex:'900',
                    // '@media screen and (min-width: 390px)': { 
                    //     top:'calc(20vh - 25px)',
                    //     left:'calc(50vw + 135px)'
                    // },
                    top:'-10px',
                    right: '25px'
              
                }}
            >
                <Fab size='medium' onClick={()=>squareBookingModal.close()} aria-label='close booking modal' sx={{position:'fixed'}}>
                    <Close/>
                </Fab>
            </Grid>
            {currentScreen === BookingScreens.SelectBarber &&
                <BarberSelect/>
            }
            {currentScreen === BookingScreens.SelectServiceAndCheckout &&
                <Grid container  sx={{margin:'0px', padding:'0px', height:'60vh', width:'auto', display:'flex', filter:'invert(91.5%)'}}>
                    <iframe style={{ margin:'0px', padding:'0px',minWidth: '320px', border:'none'}} src={selectedBarber.bookingUrl}>
                    </iframe>
                </Grid>
            }
            {currentScreen === BookingScreens.SelectServiceAndCheckout &&
                <Button 
                   
                    onClick={()=>setCurrentScreen(BookingScreens.SelectBarber)}
                    sx={{bgcolor:'rgba(60,60,60,1)', color:'rgba(180,180,180,1)', '&:hover':{bgcolor:'rgba(40,40,40,1)', color:'white'}}}
                >
                    Back
                </Button>
            }
           
        </Dialog>     
    )
  })
